#Banner0_0 .banner0-text-wrapper > .kgs36tb7e6o-editor_css {
  display: none;
}
#Content0_0 .ant-col > .content0-block-item > .kgs6f8x7kvs-editor_css {
  margin: auto auto;
}
#Banner0_0 .kgs2zmrcdzq-editor_css {
  top: 10%;
}
#Content0_0 .ant-row > .ant-col > .kgs6hr8yrsh-editor_css {
  margin: auto auto 0px;
}
#Content0_0 .kgs6iiz4lhm-editor_css > .ant-row > .kgs75c7lf3l-editor_css {
  margin: 0px auto;
}
#Pricing1_0 .ant-col > .pricing1-block-box > .kgs944vwpuk-editor_css {
  background-color: #fb9224;
}
#Pricing1_0
  .pricing1-block-box
  > .pricing1-button-wrapper
  > .kgs94sr8d4q-editor_css {
  background-color: #fb9224;
}
#Footer0_0 .home-page > .kgs956u90i-editor_css {
  color: #fbfbfb;
  background-color: #fb9224;
}
#Feature1_1 .ant-row > .ant-col > .khcjdw00icc-editor_css {
  font-size: 20px;
}
#Feature2_2 .ant-row > .ant-col > .khcjm0ewkxd-editor_css {
  font-size: 20px;
}
#Banner0_0.kgs0uhdm3nm-editor_css {
  background-image: url(https://i.imgur.com/OyNlN74.jpg);
  background-repeat: no-repeat;
}
#Banner0_0 .banner0-text-wrapper > .kgduq95viw-editor_css {
  display: flex;
  font-size: 36px;
}
#Banner0_0 .banner0-text-wrapper > .kgs7qnaw3ol-editor_css {
  display: block;
  justify-content: space-around;
}
#Pricing1_0 .ant-col > .pricing1-block-box > .kjz3pfrue8l-editor_css {
  font-size: 16px;
}
#Feature2_0 .ant-row > .ant-col > .kgs8e11xeg-editor_css {
  font-size: 18px;
}
#Feature1_2 .ant-row > .ant-col > .kjz5gaql5-editor_css {
  font-size: 18px;
}
#Feature2_3 .ant-row > .ant-col > .kjz5ktzoqa-editor_css {
  font-size: 18px;
}
